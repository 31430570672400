import AxiosApi from "@/services/axiosApi"

export const users = {
    namespaced: true,
    state: () => ({
        isLoading: false,
    }),
    getters: {
        GET_STATUS_CODE:(state: any): any => {
            return state.statusCode
        }
    },
    mutations: {
        SET_STATUS_CODE: (state: any, status: number|null|undefined): void => {
            state.statusCode = status
        }
    },
    actions: {
        async createUser({ commit }: any, { formData, companyBranchId }: { formData: any, companyBranchId: string }): Promise<void> {
            await AxiosApi.post(`/admin/users/${companyBranchId}/store`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(response => {
                commit('SET_STATUS_CODE', response?.status)
            })
        }
    }
}