import axios from '@/services/axiosApi'
import axiosApi from '@/services/axiosApi'
import { AxiosResponse } from "axios"
import { ActionContext } from "vuex"
import {
    CompaniesInterface,
    CompaniesState,
    CompanyInterface,
    GalleryInterface
} from "@/interfaces/CompaniesInterface"
import store from "@/store/index";

export const companies = {
    state: (): { companies: CompaniesInterface[]; company: CompanyInterface[], languages: string[], language_data: string[] } => ({
        companies: [] as CompaniesInterface[],
        company: [] as CompanyInterface[],
        languages: [] as string[],
        language_data: [] as string[],
    }),
    getters: {
        GET_COMPANIES: (state: CompaniesState): CompaniesInterface => {
            return state.companies
        },
        GET_COMPANY: (state: CompaniesState): CompanyInterface => {
            return state.company
        },
        GET_COMPANY_LANGUAGES: (state: CompaniesState): string[] | undefined => {
            return state.company?.data?.languages
        },
        GET_LANGUAGES: (state: CompaniesState): string[] | undefined => {
            return state.languages
        },
        GET_LANGUAGE_DATA: (state: CompaniesState): string[] | undefined => {
            return state.language_data
        },
        GET_STATUS_CODE:(state: CompaniesState): CompaniesState['statusCode'] => {
            return state.statusCode
        }
    },
    mutations: {
        SET_COMPANIES: (state: CompaniesState, data: CompaniesInterface): void => {
            state.companies = data
        },
        SET_COMPANY: (state: CompaniesState, data: any): void => {
            state.company = data
        },
        SET_LANGUAGES: (state: CompaniesState, languages: string[]): void => {
            state.languages = languages
        },
        SET_LANGUAGE_DATA: (state: CompaniesState, language_data: Record<string, any>): void => {
            state.language_data = language_data.languages.map((language: any, k: number) => {
                return {
                    id: language_data.ids[k],
                    name: language
                }
            })
        },
        SET_GALLERIES: (state: CompaniesState, galleryId: string): void => {
            if (state.company && state.company.data) {
                const { galleries = [] } = state.company.data;
                if (Array.isArray(galleries)) {
                    state.company.data.galleries = galleries.filter((gallery: GalleryInterface) => gallery.id !== galleryId);
                }
            }
        },
        SET_STATUS_CODE: (state: CompaniesState, status: number|null|undefined): void => {
            state.statusCode = status
        },
    },
    actions: {
        async fetchCompanies({ commit }: ActionContext<CompaniesState, any>): Promise<void> {
            try {
                const response: AxiosResponse<CompaniesInterface[]> = await axios.get(`/admin/companies`)
                commit('SET_COMPANIES', response.data)
            } catch (e) {
                console.error('Error fetching companies data:', e)
            }
        },
        async getCompanyDetails({ commit }: ActionContext<CompaniesState, any>, id: string): Promise<void> {
            try {
                const response: AxiosResponse<CompaniesInterface[]> = await axios.get(`/admin/companies/${id}`)
                commit('SET_COMPANY', response.data)
            } catch (e) {
                console.error('Error fetching companies data:', e)
            }
        },
        async createCompany({ commit }: ActionContext<CompaniesState, any>, {company_id, requestData}: any): Promise<void> {
            try {
                const response: AxiosResponse<any, any> = await axiosApi.post(`/admin/companies/${company_id}/create-branch`, requestData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                commit('SET_STATUS_CODE', response.status)
            } catch (e) {
                console.error('Error create company:', e)
            }
        },
        async createGallery({ commit }: ActionContext<CompaniesState, any>, {company_id, requestData}: any): Promise<void> {
            try {
                const response: AxiosResponse<any, any> = await axiosApi.post(`/admin/gallery/${company_id}/store`, requestData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                commit('SET_STATUS_CODE', response?.status)
                if (response.status === 204) {
                    await store.dispatch('getCompanyDetails', company_id)
                }
            } catch (e) {
                console.error('Error create gallery:', e)
            }
        },
        async removeGallery({ commit }: ActionContext<CompaniesState, any>, galleryId : string): Promise<void> {
            try {
                const response: AxiosResponse<any, any> = await axiosApi.delete(`/admin/gallery/${galleryId}`)
                commit('SET_STATUS_CODE', response.status)
                if (response.status === 204) {
                    store.commit('SET_GALLERIES', galleryId)
                }
            } catch (e) {
                console.error('Error create gallery:', e)
            }
        },
        async updateCompany({ commit }: ActionContext<CompaniesState, any>, {company_id, requestData}: any): Promise<void> {
            try {
                const response: AxiosResponse<any, any> = await axios.post(`/admin/companies/${company_id}?_method=PUT`, requestData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                commit('SET_STATUS_CODE', response.status)
            } catch (e) {
                console.error('Error create company:', e)
            }
        },
        async getLanguages({ commit }: ActionContext<CompaniesState, any>, {id}: any): Promise<void> {
            try {
                await axiosApi.get(`/admin/companies/${id}/languages`).then(({data}) => {
                    commit('SET_LANGUAGES', data.data.languages)
                    commit('SET_LANGUAGE_DATA', data.data)
                }).catch(e => {
                    console.error('Error create company:', e.messages)
                })
            } catch (e) {
                console.error('Error create company:', e)
            }
        },
    },
};
